import React, { useEffect, useRef, useState } from "react";
import styles from "./TerritoryScheme.module.scss";
import classnames from "../../../functions/classnames";

import Map from "../../../assets/images/map.png";
import MobileMap from "../../../assets/images/mapMob.png";
import ModalWindow from "../../elements/ModalWindow/ModalWindow";

// Legends icons
import Kpp from "../../../assets/images/territory-scheme-icons/kpp.svg";
import Visit from "../../../assets/images/territory-scheme-icons/visit.svg";
import Services from "../../../assets/images/territory-scheme-icons/services.svg";
import Garage from "../../../assets/images/territory-scheme-icons/garage.svg";
import Club from "../../../assets/images/territory-scheme-icons/club.svg";
import Seing from "../../../assets/images/territory-scheme-icons/seing.svg";
import Slip from "../../../assets/images/territory-scheme-icons/slip.svg";
import Anchor from "../../../assets/images/territory-scheme-icons/anchor.svg";
import Gas from "../../../assets/images/territory-scheme-icons/gas.svg";

// Maps icons
import map_garage from "../../../assets/images/territory-scheme-icons/maps-icons/garage.svg";
import map_services from "../../../assets/images/territory-scheme-icons/maps-icons/services.svg";
import map_visit from "../../../assets/images/territory-scheme-icons/maps-icons/visit.svg";
import map_kpp from "../../../assets/images/territory-scheme-icons/maps-icons/kpp.svg";
import map_anchor from "../../../assets/images/territory-scheme-icons/maps-icons/anchor.svg";
import map_slip from "../../../assets/images/territory-scheme-icons/maps-icons/slip.svg";
import map_seing from "../../../assets/images/territory-scheme-icons/maps-icons/seing.svg";
import map_club from "../../../assets/images/territory-scheme-icons/maps-icons/club.svg";
import map_gas from "../../../assets/images/territory-scheme-icons/maps-icons/gas.svg";

import Chip from "../../../assets/images/chip.svg";
import LabelDecor from "../../../assets/images/decor_line.svg";
import GreyWaves from "../../../assets/images/grey-waves.svg";
import IconLabel from "./IconLabel";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

const TerritoryScheme = () => {
  const [iconsWidth, setIconsWidth] = useState(41);
  const [hoveredIcon, setHoveredIcon] = useState();
  const [mobileWidth, setMobileWidth] = useState("100%");

  const mapWidth = useRef();
  const MobileMapWidth = document.getElementById("MobileMapWidth");
  const [activeImage, setActiveImage] = useState()

  useEffect(() => {
    if (!MobileMapWidth) return;
    setMobileWidth(MobileMapWidth.clientWidth);
  }, [MobileMapWidth]);

  const resize = () => {
    if (!mapWidth || !mapWidth.current) return;
    const newIconsWidth = mapWidth.current.clientWidth / 23.91;
    setIconsWidth(newIconsWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  useEffect(() => {
    resize();
  }, [mapWidth]);

  const mapIconsHover = (id) => {
    setHoveredIcon(id);
  };

  return (
    <>
      <div className={styles.wrap}>
        <img className={styles.chip} src={Chip} alt="" />
        <div className={styles.legend}>
          <h2>Схема территории</h2>

          {/* Список */}
          <ul>
            <li
              className={hoveredIcon === "kpp" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("kpp")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Kpp} alt="" />
              <span>КПП</span>
            </li>
            <li
              className={hoveredIcon === "visit" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("visit")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Visit} alt="" />
              {/* <Visit /> */}
              <span>Визит–центр</span>
            </li>
            <li
              className={hoveredIcon === "services" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("services")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Services} alt="" />
              <span>
                Ремонт <br /> и сервис
              </span>
            </li>
            <li
              className={hoveredIcon === "garage" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("garage")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Garage} alt="" />
              <span>Теплый ангар</span>
            </li>
            <li
              className={hoveredIcon === "club" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("club")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Club} alt="" />
              <span>
                Клубный <br /> дом
              </span>
            </li>
            <li
              className={hoveredIcon === "seing" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("seing")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Seing} alt="" />
              <span>
                Смотровая <br /> и маяк
              </span>
            </li>
            <li
              className={hoveredIcon === "slip" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("slip")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Slip} alt="" />
              <span>
                Оборудованный <br /> слип
              </span>
            </li>
            <li
              className={hoveredIcon === "anchor" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("anchor")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Anchor} alt="" />
              <span>Причал</span>
            </li>
            <li
              className={hoveredIcon === "gas" ? styles.activeIcon : ""}
              onMouseOver={(e) => mapIconsHover("gas")}
              onMouseOut={() => setHoveredIcon()}
            >
              <img src={Gas} alt="" />
              <span>ПЗС</span>
            </li>
          </ul>
        </div>


        {/* Схема */}
        <div className={styles.map}>
          <div>
            <div
              className={styles.map_container}
              style={window.innerWidth <= 768 ? { width: mobileWidth } : {}}
            >
              {window.innerWidth > 1000 ? (
                <img
                  className={styles.mapImg}
                  src={Map}
                  alt=""
                  ref={mapWidth}
                />
              ) : (
                <img src={MobileMap} id={"MobileMapWidth"} />
              )}

              {/* Теплый Ангар */}
              <div
                className={classnames([styles.map_garage, styles.map_item, [styles.active, hoveredIcon === "garage"]])}
                onMouseOver={(e) => mapIconsHover("garage")}
                onMouseOut={() => setHoveredIcon()}
                // onClick={() => {
                //   setActiveImage()
                // }}
              >
                <img
                  src={map_garage}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"Теплый ангар"} flag={hoveredIcon} />
              </div>

              {/* Ремонт и Сервис */}
              <div
                className={classnames([styles.map_services, styles.map_item, [styles.active, hoveredIcon === "services"]])}
                onMouseOver={(e) => mapIconsHover("services")}
                onMouseOut={() => setHoveredIcon()}
                // onClick={() => {
                //   setActiveImage()
                // }}
              >
                <img
                  src={map_services}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"Ремонт <br /> и сервис"} flag={hoveredIcon} />
              </div>

              {/* Визит центр  !!!!!!!!!!!!! */}
              <div
                className={classnames([styles.map_visit, styles.map_item, styles.photo360, [styles.active, hoveredIcon === "visit"]])}
                onMouseOver={(e) => mapIconsHover("visit")}
                onMouseOut={() => setHoveredIcon()}
                onClick={() => {
                  setActiveImage("https://ostrovkrsk.ru/photo360/visit_.jpg")
                }}
              >
                <img
                  src={map_visit}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"Визит–центр"} flag={hoveredIcon} />
              </div>

              {/* КПП  !!!!!!!!!!!!!!!!!*/}
              <div
                className={classnames([styles.map_kpp, styles.map_item, styles.photo360, [styles.active, hoveredIcon === "kpp"]])}
                onMouseOver={(e) => mapIconsHover("kpp")}
                onMouseOut={() => setHoveredIcon()}
                onClick={() => {
                  setActiveImage('https://ostrovkrsk.ru/photo360/kpp_.jpg')
                }}
              >
                <img
                  src={map_kpp}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"КПП"} flag={hoveredIcon} />
              </div>

              {/* Причал !!!!!!!!!!!!!!!!!!!!! */}
              <div
                className={classnames([styles.map_anchor, styles.map_item, styles.photo360, [styles.active, hoveredIcon === "anchor"]])}

                onMouseOver={(e) => mapIconsHover("anchor")}
                onMouseOut={() => setHoveredIcon()}
                onClick={() => {
                  setActiveImage("https://ostrovkrsk.ru/photo360/prichal_.jpg")
                }}
              >
                <img
                  src={map_anchor}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"Причал"} flag={hoveredIcon} />
              </div>

              {/* Оборудованный слип */}
              <div
                className={classnames([styles.map_slip, styles.map_item, [styles.active, hoveredIcon === "slip"]])}


                onMouseOver={(e) => mapIconsHover("slip")}
                onMouseOut={() => setHoveredIcon()}
                // onClick={() => {
                //   setActiveImage(testimage)
                // }}
              >
                <img
                  src={map_slip}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel
                  text={"Оборудованный <br/> слип"}
                  flag={hoveredIcon}
                />
              </div>

              {/* Смотровая и маяк  !!!!!!!!!!!!!!!!!!!!!! */}
              <div
                className={classnames([styles.map_seing, styles.map_item, styles.photo360, [styles.active, hoveredIcon === "seing"]])}

                onMouseOver={(e) => mapIconsHover("seing")}
                onMouseOut={() => setHoveredIcon()}
                onClick={() => {
                  setActiveImage("https://ostrovkrsk.ru/photo360/smotrovaya_.jpg")
                }}
              >
                <img
                  src={map_seing}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"Смотровая <br/> и маяк"} flag={hoveredIcon} />
              </div>

              {/* Клубный дом */}
              <div
                className={classnames([styles.map_club, styles.map_item, [styles.active, hoveredIcon === "club"]])}
                onMouseOver={(e) => mapIconsHover("club")}
                onMouseOut={() => setHoveredIcon()}
                // onClick={() => {
                //   setActiveImage()
                // }}
              >
                <img
                  src={map_club}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"Клубный <br/> дом"} flag={hoveredIcon} />
              </div>

              {/* ПЗС */}
              <div
                className={classnames([styles.map_gas, styles.map_item, [styles.active, hoveredIcon === "gas"]])}
                onMouseOver={(e) => mapIconsHover("gas")}
                onMouseOut={() => setHoveredIcon()}
                // onClick={() => {
                //   setActiveImage()
                // }}
              >
                <img
                  src={map_gas}
                  style={window.innerWidth > 768 ? { width: iconsWidth } : {}}
                  alt=""
                />
                <IconLabel text={"ПЗС"} flag={hoveredIcon} isLeft={true} />
              </div>
            </div>
            <img className={styles.greyWaves} src={GreyWaves} alt="" />
          </div>
        </div>
        {activeImage &&
        <ModalWindow setActiveImage={setActiveImage}>
          <ReactPhotoSphereViewer src={activeImage}
          containerClass={styles.sphere}
          loadingTxt="Загрузка..."  width={"80vw"} height={"80vh"} />
        </ModalWindow>
        }
      </div>
      <img className={styles.labelDecor} src={LabelDecor} alt="" />
    </>
  );
};

export default TerritoryScheme;
